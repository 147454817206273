import React, { Fragment, useContext, useState } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import { GATSBY_SKIP_FORMS } from "gatsby-env-variables"

import Section from "../../Elements/Section"
import Message from "../../Elements/Message"
import FormSelect from "../../Elements/Form/FormSelect"
import FormRadio from "../../Elements/Form/FormRadio"
import InvalidFormMessage from "../../Elements/InvalidFormMessage"
import ActionButtons from "../../Elements/ActionButtons"
import ConsentAndAuthorization from "./PreferredSchedule/ConsentAndAuthorization"

import { AppContext } from "../../../context/AppContext"
import { sendTeleconsultRequest } from "../services/sendTeleconsultRequest"
import {
  daysOfTheWeek,
  timeBlocks,
  scheduleValidationSchema,
  devSelfRequestInitialValues,
} from "../utils/formData"
import { intakeSelfRequestEmailTemplate } from "../utils/intakeSelfRequestEmailTemplate"
import { intakeSelfRequestZendeskTemplate } from "../utils/zendeskTemplates"
import FormTextArea from "../../Elements/Form/FormTextarea"
import { getSignedInUser } from "../../Auth/services/user"

const PreferredSchedule = ({ backRoute }) => {
  const { state, dispatch } = useContext(AppContext)
  const { selfRequest } = state

  const [isLoading, setLoading] = useState(false)
  const [hasErrorSubmitting, setHasErrorSubmitting] = useState(false)

  const userData = getSignedInUser()?.userData

  const handleSuccessSubmit = async () => {
    setLoading(false)
    // await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
    // await dispatch({ type: "RESET_DETAILS" })
    await dispatch({ type: "SET_SELF_REQUEST_COMPLETE" })
    navigate("/complete")
  }

  const handleErrorSubmit = ({ message }) => {
    setLoading(false)
    setHasErrorSubmitting(true)
  }

  const whichSchedulesAreDuplicates = (values) => {
    const schedules = [values.schedule1, values.schedule2, values.schedule3]
    const uniqueArray = []
    const duplicates = []
    schedules.map((schedule, index) => {
      const dayTime = `${schedule?.day?.label} ${schedule?.time?.label}`
      if (uniqueArray.includes(dayTime)) {
        duplicates.push(`schedule${index + 1}.day`)
        // duplicates.push(`schedule${index + 1}.time`)
      } else {
        uniqueArray.push(dayTime)
      }
    })

    return duplicates
  }

  const handleSubmit = async (values, { setFieldError, setFieldTouched }) => {
    setLoading(true)

    const duplicates = whichSchedulesAreDuplicates(values)
    if (duplicates.length > 0) {
      duplicates.map((duplicate) => {
        setFieldError(duplicate, "Schedule must be unique", false)
      })
      setLoading(false)
    } else {
      setHasErrorSubmitting(false)

      const employee = {
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        birthday: userData?.birthday,
        gender: userData?.gender,
        mobileNumber: userData?.mobileNumber,
        email: userData?.email,
        employeeNumber: userData?.employeeNumber,
        site: userData?.site,
        department: userData?.department,
        employmentStart: userData?.employmentStart,
      }

      const emergencyContact = userData.emergencyContact

      let newValues = {
        ...selfRequest,
        ...values,
        employee: employee,
        emergencyContact: emergencyContact,
      }

      sendTeleconsultRequest({
        requestData: newValues,
        ticketTemplate: intakeSelfRequestZendeskTemplate,
        emailTemplate: intakeSelfRequestEmailTemplate,
        callback: handleSuccessSubmit,
        errorCallback: handleErrorSubmit,
        type: "selfRequest",
      })
    }
  }

  const scheduleOptions = [1, 2, 3]

  return (
    <Formik
      initialValues={
        GATSBY_SKIP_FORMS ? devSelfRequestInitialValues : selfRequest
      }
      validationSchema={scheduleValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isValid, submitCount, errors }) => (
        <Form key="preferredSchedule">
          <Section title="Tell us when you are most available">
            {scheduleOptions.map((option) => (
              <Fragment>
                Choice {option}
                <div className="columns is-mobile mb-0">
                  <div className="column pb-0">
                    <FormSelect
                      name={`schedule${option}.day`}
                      placeholder="Choose day..."
                      options={daysOfTheWeek}
                      value={values?.[`schedule${option}`]?.day}
                      errors={errors}
                      marginBottom={2}
                      isRequired
                      submitCount={submitCount}
                    />
                  </div>
                  <div className="column pb-0">
                    <FormSelect
                      name={`schedule${option}.time`}
                      placeholder="Choose time..."
                      options={timeBlocks}
                      value={values?.[`schedule${option}`]?.time}
                      errors={errors}
                      marginBottom={2}
                      submitCount={submitCount}
                      isRequired
                    />
                  </div>
                </div>
              </Fragment>
            ))}

            <FormRadio
              name="usedGoogleMeet"
              title="Have you used Google Meet to go on video calls before?"
              value={values?.usedGoogleMeet}
              options={["Yes", "No"]}
              isRequired
            />
            <hr className="has-background-light" />
            <FormTextArea
              name="notes"
              label="Thank you for answering this intake form. Is there anything else you would like us to know?"
            />
          </Section>

          <ConsentAndAuthorization values={values} />
          <div className="my-3" />
          {!isValid && submitCount > 0 && (
            <InvalidFormMessage color="danger" name="missingRequiredFields" />
          )}
          {hasErrorSubmitting && (
            <Message color="warning">
              Your request failed to submit. Please check your network
              connection and try again.
            </Message>
          )}
          <ActionButtons
            submit={{
              label: "Request Teleconsult",
              loading: isLoading,
              disabled:
                !values?.terms?.length || !values?.informedConsent?.length,
            }}
            back={{ label: "Back", link: backRoute }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default PreferredSchedule
