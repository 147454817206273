import React, { Fragment } from "react"
import classNames from "classnames"
import { Field, useFormikContext } from "formik"

const FormLikertSlider = ({
  name,
  label,
  options = ["Strongly disagree", "Strongly agree"],
}) => {
  const { values, setFieldValue } = useFormikContext()

  const handleSliderChange = (event) => {
    setFieldValue(name, event.currentTarget.value)
  }

  const LikertSlider = () => (
    <input
      className="slider is-fullwidth is-medium mt-0"
      step="1"
      min="0"
      max={options.length - 1}
      value={values[name] || Math.floor(options.length / 2)} // Ensures defaultValue is middle option
      type="range"
      onChange={handleSliderChange}
    />
  )
  return (
    <Fragment>
      {label}
      <div className="columns my-0 is-mobile">
        {options?.map((option, index) => (
          <div className="column has-text-centered is-size-7 pb-0 px-0">
            {option}
          </div>
        ))}
      </div>
      <div className="columns is-mobile is-centered">
        <div className="column is-11">
          <center>
            <Field name={name} component={LikertSlider} />
            <div
              className={classNames(
                { "is-invisible": !values[name] },
                "tag is-medium is-primary"
              )}
            >
              {options[values[name]]}
            </div>
          </center>
        </div>
      </div>
    </Fragment>
  )
}

export default FormLikertSlider
