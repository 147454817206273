import cssrsJson from "../utils/cssrs.json"
import kessler6Json from "../utils/kessler6.json"

export const intakeSelfRequestEmailTemplate = (data, zendeskID) => {
  const {
    employee,
    emergencyContact,
    hasConcern,
    raisedConcern,
    causeDistress,
    seenMHP,
    seenMedGrocerMHP,
    medGrocerMHP,
    hospitalizedForPsychiatricReasons,
    hasPsychiatricMeds,
    psychiatricMeds,
    hasNonPsychiatricMeds,
    nonPsychiatricMeds,
    hasDrugAllergies,
    drugAllergies,
    beenDiagnosed,
    pastDiagnoses,
    hasWishedToBeDead,
    cssrs,
    kessler6,
    notes,
    schedule1,
    schedule2,
    schedule3,
    usedGoogleMeet,
  } = data

  const createTableRows = (json, dataSource) => {
    const rows = []
    json.map((field) => {
      rows.push(
        `<tr><td>${field.label}</td><td>${dataSource[field.name]}</td></tr>`
      )
    })

    return rows.join("")
  }

  const zendeskIDTemplate = zendeskID
    ? `<h3 style="text-align: center;">Teleconsult Ticket Number</h3><h3 style="text-align: center; background: #fadea4; padding: 1em; margin: auto; width: 20em;">${zendeskID}</h3><br/>`
    : ""

  const tdSchedule2 = !!schedule2?.day?.value
    ? `<br />Option 2: ${schedule2?.day?.value}, ${schedule2?.time?.value}`
    : ``
  const tdSchedule3 = !!schedule3?.day?.value
    ? `<br />Option 3: ${schedule3?.day?.value}, ${schedule3?.time?.value}`
    : ``
  // const formattedPersonalEmail = employee?.personalEmail
  //   ? `<tr><td>Personal Email</td><td>${employee.personalEmail}</td></tr>`
  //   : ``

  const cssrsHtml = createTableRows(cssrsJson, cssrs)

  const kessler6Html = createTableRows(kessler6Json, kessler6)

  return `
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <html lang="en">
    <head>
      <style>
        @import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
      </style>
      <style type="text/css">
        @media only screen and (max-width: 770px) {
          .logo {
            width: 100%;
          }
        }

        @media screen {
          .logo {
            width: 30%;
          }
        }

        body {
          font-family: "Lato", sans-serif;
          line-height: 1.25;
        }

        .subtitle {
          margin-top: 2.25rem;
        }

        b {
          color: #0d6d6e;
        }

        .content {
          width: 95%;
          margin: auto;
        }

        @media only screen and (min-width: 770px) {
          .content {
            width: 60%;
          }
        }

        table {
          border-collapse: collapse;
          width: 100%;
        }

        table,
        th,
        td {
          border: 1px solid #ccc;
        }

        td {
          padding: 10px;
        }

        h3 {
          margin-top: 0.75rem;
          color: #0d6d6e;
        }

        .terms {
          padding: 10px;
          background: #eeeeee;
          font-size: 11px;
          color: #606060;
        }

        .has-text-right {
          text-align: right;
        }

        .terms th,
        .terms td {
          padding: 5px;
        }
      </style>
    </head>

    <body>
      <div class="container">
        <div class="content">
          <img
            src="https://s3-ap-southeast-1.amazonaws.com/data.medgrocer.com/public/logo/mgsolo_teal.png"
            class="logo"
            width="200"
          />

          <p class="subtitle">Hi <b>${employee.firstName}</b>,</p>
          <p>
            Thank you for submitting your request for a teleconsult. Our coordinator will email
            you with a proposed schedule within 24 hours.
          </p>

          ${zendeskIDTemplate}

          <p>
            Below is a copy of your request. If you wish to correct
            anything, please send an email to
            <a
              href="mailto:wellness@medgrocer.com?Subject=Change%20Intake%20Details:%20Ticket%20#${zendeskID}"
              target="_blank"
              >wellness@medgrocer.com</a
            > with the subject line "Change
            Intake Details: Ticket #${
              zendeskID || "(Your Teleconsult Ticket Number)"
            }".
          </p>

          <p>If you need urgent help, please notify HR immediately.</p>

          <br />
          <p>
            Regards, <br />
            <strong>The MedGrocer Team</strong>
          </p>
          <br />
          <hr style="background-color: #ccc;" />
          <p class="subtitle is-primary">
            <b>Review Your Responses</b>
          </p>
          <br />

          <table class="table">
            <tbody>
              <tr>
                <td colspan="2"><b>Your Information</b></td>
              </tr>
              <tr>
                <td width="40%">Name</td>
                <td>${employee.firstName} ${employee.lastName}</td>
              </tr>
              <tr>
                <td>Birthday</td>
                <td>
                  ${employee.birthday.month.value} ${
    employee.birthday.date.value
  }
                  ${employee.birthday.year}
                </td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>
                  ${employee.gender}
                </td>
              </tr>
              <tr>
                <td>Mobile Number</td>
                <td>${employee.mobileNumber}</td>
              </tr>
              <tr>
                <td>Work Email</td>
                <td>${employee.email}</td>
              </tr>
              <tr>
                <td>Employee Number</td>
                <td>${employee.employeeNumber}</td>
              </tr>
              <tr>
                <td>Site</td>
                <td>${employee.site.label}</td>
              </tr>
              <tr>
                <td>Account/Department</td>
                <td>${employee.department}</td>
              </tr>
              <tr>
                <td>Employment Start</td>
                <td>${employee.employmentStart || "N/A"}</td>
              </tr>
              <tr>
                <td colspan="2"><b>Emergency Contact</b></td>
              </tr>
              <tr>
                <td width="40%">Name</td>
                <td>${emergencyContact.name}</td>
              </tr>
              <tr>
                <td>Mobile Number</td>
                <td>${emergencyContact.mobileNumber}</td>
              </tr>
              <tr>
                <td>Relationship</td>
                <td>${emergencyContact.relationship.label}</td>
              </tr>
              <tr>
                <td colspan="2"><b>Medical History</b></td>
              </tr>
              <tr>
                <td width="40%">
                  Is there a specific issue or problem you’re seeking help for?
                </td>
                <td>${hasConcern === "Yes" ? raisedConcern : hasConcern}</td>
              </tr>
              <tr>
                <td width="40%">
                  What causes you distress?
                </td>
                <td>${causeDistress}</td>
              </tr>
              <tr>
                <td width="40%">
                  Is there a specific issue or problem you’re seeking help for?
                </td>
                <td>${hasConcern === "Yes" ? raisedConcern : hasConcern}</td>
              </tr>
              <tr>
                <td>
                  Have you consulted a psychologist/psychiatrist under this
                  program before?
                </td>
                <td>${
                  seenMedGrocerMHP === "Yes" ? medGrocerMHP : seenMedGrocerMHP
                }</td>
              </tr>
              <tr>
                <td>
                  Have you consulted a psychologist/psychiatrist in any other
                  setting before?
                </td>
                <td>${seenMHP}</td>
              </tr>
              <tr>
                <td>
                  Have you been hospitalized for mental health reasons?
                </td>
                <td>${hospitalizedForPsychiatricReasons}</td>
              </tr>
              <tr>
                <td>
                Have you taken or are currently taking psychiatric medicines?
                </td>
                <td>${
                  hasPsychiatricMeds === "Yes"
                    ? psychiatricMeds
                    : hasPsychiatricMeds
                }</td>
              </tr>
              <tr>
                <td>Are you currently taking non-psychiatric medicines?</td>
                <td>${
                  hasNonPsychiatricMeds === "Yes"
                    ? nonPsychiatricMeds
                    : hasNonPsychiatricMeds
                }</td>
              </tr>
              <tr>
                <td>Do you have drug allergies?</td>
                <td>${
                  hasDrugAllergies === "Yes" ? drugAllergies : hasDrugAllergies
                }</td>
              </tr>
              <tr>
                <td>Have you been diagnosed with a mental illness?</td>
                <td>
                ${beenDiagnosed === `Yes` ? pastDiagnoses : beenDiagnosed}
                </td>
              </tr>
              <tr>
                <td>Is there anything else you would like us to know?</td>
                <td>${notes || ""}</td>
              </tr>
              <tr>
                <td colspan="2"><b>Self-assessment</b></td>
              </tr>
              ${hasWishedToBeDead.includes("Yes") ? cssrsHtml : kessler6Html}
              <tr>
                <td colspan="2"><b>Schedule</b></td>
              </tr>
              <tr>
                <td>Availability</td>
                <td>
                  Option 1: ${schedule1?.day?.value}, ${schedule1?.time?.value}
                  ${tdSchedule2}
                  ${tdSchedule3}
                </td>
              </tr>
              <tr>
                <td>Have you used Google Meet to go on video calls before?</td>
                <td>${usedGoogleMeet}</td>
              </tr>
            </tbody>
          </table>

          <br />
          <br />
          <div class="terms">
            <p>
              This email serves as confirmation that you:
            </p>

            <ul>
              <li>
                Understand and agree to MedGrocer’s
                <a
                  href="https://wellness.medgrocer.com/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Terms and Conditions</a
                >
                and
                <a
                  href="https://wellness.medgrocer.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Privacy Policy</a
                >
              </li>
              <li>
                Understand and consent to your rights and responsibilities, and
                those of mental health professionals, as outlined in the
                <a
                  href="https://wellness.medgrocer.com/informed-consent"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Informed Consent Form</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </body>
  </html>
`
}
