import React, { useContext } from "react"
import Layout from "../../../components/Layout/Layout"
import Container from "../../../components/Layout/Container"
import PreferredSchedule from "../../../components/Intake/SelfRequest/PreferredSchedule"
import { AppContext } from "../../../context/AppContext"

export default () => {
  const { state } = useContext(AppContext)
  const { wishedToBeDead } = state?.selfRequest
  const backRoute = wishedToBeDead?.includes("Yes")
    ? "/intake/self/assessment"
    : "/intake/self/history"
  return (
    <Layout
      title="Schedule"
      subtitle="A teleconsult typically lasts for an hour"
      seoTitle="Schedule (Self-request Teleconsult)"
      display={{ footer: false, helpCenterBanner: false }}
    >
      <Container isCentered desktop={6} fullhd={6}>
        <PreferredSchedule backRoute={backRoute} />
      </Container>
    </Layout>
  )
}
