import React, { Fragment, useRef, useEffect } from "react"
import { Field, FieldArray, ErrorMessage } from "formik"
import { camelize } from "humps"
import classNames from "classnames"

import styles from "../utils/elements.module.scss"
import { generateFormField, getFollowUpQuestionData } from "./services/form"

/**
 ** Checkbox group linked in formik.
 ** Supported parameters:
 **
 ** fieldProps: {
 **               'name': String,
 **               'values': Object [],
 **               'label': String,
 **               'options': Object [],
 **               'onChange': Function,
 **               ...props compatible in Field Component of Formik
 **             }
 **/
const FormCheckbox = ({
  name,
  options,
  values = [],
  onChange,
  title,
  disabled,
  isRequired,
  followUpQuestions = [],
  formFields,
  formValues,
  setFieldValue,
  isFollowUpQuestion,
  hideOptions,
  removeMargins,
}) => {
  const fieldRef = useRef(null)

  const handleScrollCallback = () => {
    fieldRef.current.scrollIntoView({ scroll: "smooth", block: "center" })
  }

  useEffect(() => {
    if (isFollowUpQuestion) handleScrollCallback()
  }, [])

  // Custom handleChange of every checkbox in the group
  //
  // Note: Curried function: need the form from the Field and option (text from values)
  //       to return then (event) => { ... }
  const handleChange = (form, option) => (event) => {
    const { setFieldValue } = form
    let newValue = [...values]
    if (event.target.checked) newValue.push(option)
    else newValue = newValue.filter((element) => element !== option)
    setFieldValue(name, newValue)

    if (onChange) onChange(event)
  }

  const CheckBox = ({ form, option, index }) => (
    <span>
      <input
        className={classNames("is-checkradio")}
        type="checkbox"
        checked={values.find((element) => element === option)}
        onChange={handleChange(form, option)}
        name={`${name}[${index}]`}
        id={`${name}[${index}]`}
        disabled={disabled}
      />
      <label
        className={classNames("checkbox-label", styles["form__checkboxLabel"])}
        htmlFor={`${name}[${index}]`}
      >
        {!hideOptions && (
          <span className="ml-1">
            <span dangerouslySetInnerHTML={{ __html: option }} />
          </span>
        )}
      </label>
    </span>
  )

  return (
    <Fragment>
      {title && (
        <label
          className={classNames("label has-text-weight-normal")}
          ref={fieldRef}
        >
          {title}
        </label>
      )}
      <FieldArray name={name}>
        {() =>
          options.map((option, index) => (
            <div key={index} className={classNames({ "mb-1": !removeMargins })}>
              <Field>
                {({ form }) => (
                  <CheckBox form={form} option={option} index={index} />
                )}
              </Field>
              <div className={classNames("px-1", styles["backgroundAnimated"])}>
                {followUpQuestions.map((followUpQuestion) => {
                  const getFormField = getFollowUpQuestionData({
                    followUpQuestion,
                    formFields: formFields,
                  })
                  if (
                    values.includes(option) &&
                    getFormField?.referenceAnswer === camelize(option)
                  )
                    return (
                      <div className="notification is-light ml-2 mt-1">
                        {generateFormField({
                          formField: { ...getFormField, disabled: disabled },
                          formFields,
                          values: formValues,
                          setFieldValue,
                        })}
                      </div>
                    )
                })}
              </div>
            </div>
          ))
        }
      </FieldArray>
      <p
        className={classNames("help is-danger mt-0", {
          "mb-1": !removeMargins,
        })}
      >
        <ErrorMessage name={name} />
      </p>
    </Fragment>
  )
}

export default FormCheckbox
