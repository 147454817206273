import axios from "axios"
import {
  GATSBY_ENV,
  GATSBY_ZENDESK_EMAIL,
  GATSBY_ZENDESK_API_KEY,
  GATSBY_ZENDESK_API_URL,
} from "gatsby-env-variables"

const zendeskApiKey = GATSBY_ZENDESK_API_KEY
const TEST_ENV = GATSBY_ENV === "production" ? "" : "[TEST]"

export const zendeskApi = (email = GATSBY_ZENDESK_EMAIL) => {
  let zendeskKey = email + "/token:" + zendeskApiKey
  let encryptedKey = Buffer.from(zendeskKey).toString("base64")
  let api = axios.create({
    baseURL: GATSBY_ZENDESK_API_URL,
    headers: {
      Authorization: "Basic " + encryptedKey,
      "Content-Type": "application/json",
    },
  })

  return api
}

export const zendeskAPI = zendeskApi()

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || ""
  sliceSize = sliceSize || 512
  let byteCharacters = atob(b64Data)
  let byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize)
    let byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    let byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  let blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const generateUploadToken = (uploadedFiles) =>
  uploadedFiles.map((response) => {
    return response.data.upload.token
  })

export const generateUploadUrl = (uploadedFiles) =>
  uploadedFiles.map((response) => {
    return response.data.upload.attachment.content_url
  })

const checkFileSize = (fileArray) => {
  const MAX_SIZE = 10485760
  for (let i = 0; i < fileArray.length; i++) {
    if (fileArray[i].path.size >= MAX_SIZE)
      throw "File is too big to attach in zendesk."
  }
}

export const sendToZendesk = async (request) => {
  const comment = { ...request.comment }
  const requestTemplate = { request: { ...request, comment } }
  return await zendeskApi().post("/requests.json", requestTemplate)
}

export const zendeskUploadFiles = (fileArray, callback) =>
  axios.all(
    fileArray.map((file) => {
      const b64Data = file.path.split(",")[1]
      const contentType = file.path.split(",")[0].match(/:(.*?);/)[1]
      return zendeskApi().post(
        `/uploads.json?filename=${file.filename}`,
        b64toBlob(b64Data, contentType),
        { headers: { "Content-Type": "application/binary" } }
      )
    })
  )

export const generateTemplate = (
  subject,
  email,
  template,
  templateObjects,
  tags,
  name
) => {
  return {
    type: "request",
    subject: `${TEST_ENV} ${subject} ${name}`,
    requester: { name: name, email },
    comment: { body: template(templateObjects) },
    tags,
  }
}
