import React from "react"

import FormInput from "../FormInput"
import FormCheckbox from "../FormCheckbox"
import FormRadio from "../FormRadio"
import FormSelect from "../FormSelect"
import FormDate from "../FormDate"
import FormTextArea from "../FormTextarea"
import FormLikertRadio from "../FormLikertRadio"
import FormLikertSlider from "../FormLikertSlider"
import FormSchedule from "../FormSchedule"
import Address from "../../Address"

// const handleChange = ({ setFieldValue, followUpQuestions }) => {
//   followUpQuestions.map((followUpQuestion) => {
//     setFieldValue(followUpQuestion, [])
//   })
// }

export const generateFormField = (config) => {
  let {
    formField,
    formFields,
    values,
    setFieldValue,
    onChange,
    errors,
    submitCount,
  } = config
  switch (formField?.type) {
    case "text":
    case "number":
    case "email":
    case "password":
      return (
        <FormInput
          type={formField?.type === "text" ? "" : formField?.type}
          isFollowUpQuestion
          setFieldValue={setFieldValue}
          {...formField}
        />
      )
    case "date":
      return (
        <FormDate
          values={values[formField?.name]}
          onChange={onChange}
          {...formField}
        />
      )
    case "checkbox":
      return (
        <FormCheckbox
          values={values[formField?.name]}
          title={formField?.label}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          // onChange={(event) => {
          //   if (setFieldValue)
          //     handleChange({
          //       setFieldValue,
          //       followUpQuestions: formField?.followUpQuestions,
          //     })
          // }}
          {...formField}
        />
      )
    case "radio":
      return (
        <FormRadio
          value={values[formField?.name]}
          title={formField?.label}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          // onChange={() =>
          //   setFieldValue &&
          //   handleChange({
          //     setFieldValue,
          //     followUpQuestions: formField?.followUpQuestions,
          //   })
          // }
          {...formField}
        />
      )
    case "scale":
      return (
        <FormLikertRadio
          value={values[formField?.name]}
          title={formField?.label}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          {...formField}
        />
      )
    case "schedule":
      return (
        <FormSchedule
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          otherSchedules={formField.otherScheduleNames.map(
            (otherSchedule) => values[otherSchedule]
          )}
          errors={errors}
          submitCount={submitCount}
          {...formField}
        />
      )
    case "slider":
      return (
        <FormLikertSlider
          value={values[formField?.name]}
          title={formField?.label}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          {...formField}
        />
      )
    case "select":
      return (
        <FormSelect
          value={values[formField?.name]}
          values={values}
          {...formField}
        />
      )
    case "textarea":
      return <FormTextArea value={values[formField?.name]} 
        setFieldValue={setFieldValue}
        {...formField}
      />
    case "address":
      return (
        <Address
          setFieldValue={setFieldValue}
          values={values}
          {...formField}
          isNationwide
          isRequired
        />
      )
    default:
      return null
  }
}

export const getFollowUpQuestionData = (config) => {
  let { followUpQuestion, formFields } = config
  let followUpData = formFields.filter((formField) => {
    return followUpQuestion === formField["name"]
  })
  if (followUpData.length > 0) return followUpData[0]
  else return {}
}
