import { convertToBoolean } from "services/general"
import { dateBuilder } from "services/general"

export const generateConsultFirebaseDocument = ({
  requestData,
  zendeskId,
  userData,
  environment,
  website,
  type,
}) => {
  let user = userData

  const preferredSchedule = {
    firstChoiceSchedule: {
      day: requestData.firstChoiceSchedule.day.value,
      time: requestData.firstChoiceSchedule.time.value,
    },
    secondChoiceSchedule: {
      day: requestData.secondChoiceSchedule.day.value,
      time: requestData.secondChoiceSchedule.time.value,
    },
    thirdChoiceSchedule: {
      day: requestData.thirdChoiceSchedule.day.value,
      time: requestData.thirdChoiceSchedule.time.value,
    },
  }

  let document = {
    created: new Date(),
    preferredSchedule,
    specificProblemIssue: requestData.specificProblemIssue || "N/A",
    specificProblemIssueDetails: requestData.specificProblemIssue || "N/A",
    causeOfDistress: requestData.causeOfDistress || "N/A",
    hasConsultedWithPsychOutsideProgram:
      requestData.hasConsultedWithPsychOutsideProgram ||
      requestData.hadConsultedWithPsychOutsideProgram ||
      "N/A",
    hasConsultedWithPsychInProgram:
      requestData.hasConsultedWithPsychInProgram ||
      requestData.hadConsultedWithPsychInProgram ||
      "N/A",
    previousPsychName: requestData.previousPsychName || "N/A",
    hasBeenHospitalized:
      requestData.hasBeenHospitalized ||
      requestData.hadBeenHospitalized ||
      "N/A",
    isTakingPsychMeds: requestData.isTakingPsychMeds || "N/A",
    psychMedsDetails: requestData.psychMedsDetails || "N/A",
    isTakingNonPsychMeds: requestData.isTakingNonPsychMeds || "N/A",
    nonPsychMedsDetails: requestData.nonPsychMedsDetails || "N/A",
    hasDrugAllergies:
      requestData.hasDrugAllergies || requestData.hadDrugAllergies || "N/A",
    drugAllergiesDetails: requestData.drugAllergiesDetails || "N/A",
    hasBeenDiagnosed:
      requestData.hasBeenDiagnosed || requestData.hasBeenDiagnosed || "N/A",
    mentalIllnessDiagnosis: requestData.mentalIllnessDiagnosis || "N/A",
    kesslerNervous: requestData.kesslerNervous || "N/A",
    kesslerHopeless: requestData.kesslerHopeless || "N/A",
    kesslerRestless: requestData.kesslerRestless || "N/A",
    kesslerDepressed: requestData.kesslerDepressed || "N/A",
    kesslerEffort: requestData.kesslerEffort || "N/A",
    kesslerWorthless: requestData.kesslerWorthless || "N/A",
    hasWishedToBeDead:
      requestData.hasWishedToBeDead || requestData.hadWishedToBeDead || "N/A",
    hasUsedGoogleMeet:
      requestData.hasUsedGoogleMeet || requestData.hadUsedGoogleMeet || "N/A",
    otherNotes: requestData.otherNotes || "N/A",
    userId: user.id,
    authUid: user.authUid,
    type: "MGXSOLMH",
    hadSuicidalThoughts:
      requestData.cssrs.hadSuicidalThoughts ||
      requestData.cssrs.hasSuicidalThoughts ||
      null,
    hasThoughtSuicidalMethod:
      requestData.cssrs.hasThoughtSuicidalMethod ||
      requestData.cssrs.hadThoughtSuicidalMethod ||
      null,
    hadSuicidalIntent:
      requestData.cssrs.hadSuicidalIntent ||
      requestData.cssrs.hasSuicidalIntent ||
      null,
    hadSuicidalIntentWithPlan:
      requestData.cssrs.hadSuicidalIntentWithPlan ||
      requestData.cssrs.hasSuicidalIntentWithPlan ||
      null,
    hadActedOnSuicidalThoughts:
      requestData.cssrs.hasActedOnSuicidalThoughts ||
      requestData.cssrs.hadActedOnSuicidalThoughts ||
      null,
    website,
    environment,
    type,
  }

  document = convertToBoolean({ data: document })

  if (zendeskId) {
    document.zendeskId = zendeskId
  }

  return document
}
