const printDateValue = (date) => {
  return `${date?.month?.label} ${
    date?.date?.label ? `${date?.date?.label}, ` : ""
  }${date?.year ? `${date?.year}` : ""}`
}

export const intakeSelfRequestZendeskTemplate = (data) => {
  let {
    employee,
    emergencyContact,
    specificProblemIssue,
    causeOfDistress,
    hasConsultedWithPsychInProgram,
    hasConsultedWithPsychOutsideProgram,
    hasBeenHospitalized,
    isTakingPsychMeds,
    isTakingNonPsychMeds,
    hasDrugAllergies,
    hasBeenDiagnosed,
    specificProblemIssueDetails,
    previousPsychName,
    psychMedsDetails,
    nonPsychMedsDetails,
    drugAllergiesDetails,
    ifScheduleUnavailable,
    mentalIllnessDiagnosis,
    kesslerNervous,
    kesslerHopeless,
    kesslerRestless,
    kesslerDepressed,
    kesslerEffort,
    kesslerWorthless,
    hasWishedToBeDead,
    firstChoiceSchedule,
    secondChoiceSchedule,
    thirdChoiceSchedule,
    hasUsedGoogleMeet,
    otherNotes,
    cssrs,
  } = data

  let {
    hadSuicidalThoughts,
    hasThoughtSuicidalMethod,
    hadSuicidalIntent,
    hadSuicidalIntentWithPlan,
    hasActedOnSuicidalThoughts,
  } = cssrs

  let {
    firstName,
    lastName,
    gender,
    birthday,
    site,
    department,
    mobileNumber,
    email,
    employeeNumber,
  } = employee

  let personalDetailsBody = `  - Personal Details -
  Name: ${firstName} ${lastName}
  Birthday: ${birthday.month.value} ${birthday.date.value} ${birthday.year}
  Gender: ${gender}
  Mobile Number: ${mobileNumber}
  
  - Employee Information -
  Work Email: ${email}
  Employee Number: ${employeeNumber}
  Site: ${site.label}
  Account/Department: ${department}
  
  - Emergency Contact -
  Name: ${emergencyContact.name}
  Mobile Number: ${emergencyContact.mobileNumber}
  Relationship to Emergency Contact: ${
    emergencyContact.relationship?.label ||
    emergencyContact.relationship ||
    "N/A"
  }`

  let cssrsBody = `Have you actually had any thoughts of killing yourself? ${
    hadSuicidalThoughts || "N/A"
  }\nHave you been thinking about how you might do this? ${
    hasThoughtSuicidalMethod || "N/A"
  }\nHave you had these thoughts and had some intention of acting on them? ${
    hadSuicidalIntent || "N/A"
  }\nHave you started to work out or worked out the details of how to kill yourself? Do you intend to carry out this plan? ${
    hadSuicidalIntentWithPlan || "N/A"
  }\nHave you ever done anything, started to do anything, or prepared to do anything to end your life? ${
    hasActedOnSuicidalThoughts || "N/A"
  }`

  let kessler6Body = `In the past month, have you felt nervous? ${
    kesslerNervous || "N/A"
  }\nHopeless? ${kesslerHopeless || "N/A"}\nRestless or fidgety? ${
    kesslerRestless || "N/A"
  }\nSo depressed that nothing could cheer you up? ${
    kesslerDepressed || "N/A"
  }\nEverything needed effort to accomplish? ${
    kesslerEffort || "N/A"
  }\nWorthless? ${kesslerWorthless || "N/A"}`

  let medicalHistoryBody = `- Medical History -\nRaised Concern: ${
    specificProblemIssue === "Yes"
      ? specificProblemIssueDetails || "N/A"
      : specificProblemIssue || "N/A"
  }\nCause of Distress: ${
    causeOfDistress || "N/A"
  }\nSeen a MHP outside MedGrocer: ${
    hasConsultedWithPsychOutsideProgram || "N/A"
  }\nSeen a MedGrocer MHP: ${
    hasConsultedWithPsychInProgram === "Yes"
      ? previousPsychName || "N/A"
      : hasConsultedWithPsychInProgram || "N/A"
  }\nHospitalized for MH reasons: ${
    hasBeenHospitalized || "N/A"
  }\nHas taken/is currently taking psychiatric meds: ${
    isTakingPsychMeds === "Yes"
      ? psychMedsDetails || "N/A"
      : isTakingPsychMeds || "N/A"
  }\nIs taking non-psychiatric meds: ${
    isTakingNonPsychMeds === "Yes"
      ? nonPsychMedsDetails || "N/A"
      : isTakingNonPsychMeds || "N/A"
  }\nHas drug allergies: ${
    hasDrugAllergies === "Yes"
      ? drugAllergiesDetails || "N/A"
      : hasDrugAllergies || "N/A"
  }\nHad a previous MH diagnosis: ${
    hasBeenDiagnosed === "Yes"
      ? mentalIllnessDiagnosis || "N/A"
      : hasBeenDiagnosed || "N/A"
  }\nOther notes: ${otherNotes || "N/A"}`

  let scheduleBody = `- Availability -\nOption 1: ${
    firstChoiceSchedule?.day?.value
  }, ${firstChoiceSchedule?.time?.value}${
    secondChoiceSchedule?.day?.value
      ? `\nOption 2: ${secondChoiceSchedule?.day?.value}, ${secondChoiceSchedule?.time?.value}`
      : ""
  }${
    thirdChoiceSchedule?.day?.value
      ? `\nOption 3: ${thirdChoiceSchedule?.day?.value}, ${thirdChoiceSchedule?.time?.value}`
      : ""
  }\n\nUsed GMeet before: ${hasUsedGoogleMeet} \n If my preferred schedule is not available within the next 7 days: ${ifScheduleUnavailable}`

  return `${personalDetailsBody}\n\n${medicalHistoryBody}\n\n- Self-assessment -
  ${kessler6Body}\nHave you wished you were dead or wished you could go to sleep and not wake up? ${hasWishedToBeDead}
  ${hasWishedToBeDead.includes("Yes") ? cssrsBody : ""}\n\n${scheduleBody}`
}

export const intakeReferralZendeskTemplate = (data) => {
  let { referrer, employee, reasonForReferral, riskAssessmentScore } = data

  return `- Referrer Information -
  Current Role: ${referrer.currentRole}
  Name: ${referrer.firstName} ${referrer.lastName}
  Mobile Number: ${referrer.mobileNumber}
  Work Email: ${referrer.email}
  
  - Employee Information -
  Name: ${employee.firstName} ${employee.lastName}
  Birthday: ${
    employee.birthday.month.value
      ? `${employee.birthday.month.value || ""} ${
          employee.birthday.date.value || ""
        } ${employee.birthday.year || ""}`
      : "N/A"
  } 
  Mobile Number: ${employee.mobileNumber}
  Work Email: ${employee.email}
  Employee Number: ${employee.employeeNumber || "N/A"}
  Site: ${employee.site.label}
  Account/Department: ${employee.department}
  Current Work Hours: ${employee.currentWorkHours || "N/A"}
  
  Reason for Referral: ${reasonForReferral}
  Risk Assessment Score: ${riskAssessmentScore.value || "N/A"}`
}
