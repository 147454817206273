import React from "react"
import classnames from "classnames"
import { Field, ErrorMessage } from "formik"

const FormTextArea = ({
  label,
  isRequired,
  placeholder,
  children,
  name,
  rows,

  maxLength,
  value,
  setFieldValue,
  readOnly,
}) => {
  return (
    <div className="field mb-1">
      {!!label && (
        <label className={classnames("label has-text-weight-normal")}>
          {label}
          {!isRequired && (
            <span className="is-italic has-text-grey"> (Optional)</span>
          )}
        </label>
      )}
      <div className="field-body">
        <div className="field">
          <p className="control">
            <Field name={name}>
              {({ field }) => (
                <textarea
                  className="textarea has-fixed-size"
                  placeholder={placeholder}
                  name={name}
                  rows={rows}
                  value={value}
                  maxLength={maxLength}
                  {...field}
                  readOnly={readOnly}
                  onBlur={(event) => {
                    if (setFieldValue) {
                      setFieldValue(name, event.target.value.trim())
                    }
                  }}
                >
                  {children}
                </textarea>
              )}
            </Field>
          </p>
          <ErrorMessage name={name}>
            {(error) => (
              <div className="has-text-danger is-size-7">{error}</div>
            )}
          </ErrorMessage>
        </div>
      </div>
    </div>
  )
}

export default FormTextArea
