import React from "react"
import classNames from "classnames"
import _ from "lodash"
import { Field, ErrorMessage } from "formik"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"

/**
 ** Select field with label and error message.
 ** Supported parameters:
 **
 ** fieldProps: {
 **               'name': String,
 **               'placeholder': String,
 **               'label': String,
 **               'option': Object [],
 **               'onChange': Function,
 **               'value': Object {},
 **             }
 **
 ** Note: You need a list of objects as options with label and value element.
 **/

const customStyles = (theme) => ({
  ...theme,
  boxShadow: "inset 0 0.0625em 0.125em rgba(0, 0, 0, 0.05)",
  colors: {
    ...theme.colors,
    primary: "#6ea9a9",
    primary25: "#cfe1e1",
    primary50: "#9ec5c5",
  },
})

const FormSelect = (props) => {
  //* destructure props
  const {
    name,
    label,
    onChange,
    isRequired,
    marginBottom,
    isSelectable,
    errors,
    submitCount,
  } = props
  //* Function to set the value of the react-select in
  //* formik values.
  //*
  //* Note: Curried Function.
  //*       Need to call handleChange(form) to return (selectedValue) => { ... }
  const handleChange = (form) => (selectedValue) => {
    form.setFieldValue(name, selectedValue)
    if (onChange) onChange(selectedValue, form.setFieldValue)
  }
  const SelectComponent = ({ form }) => (
    <Select
      {...props}
      className="is-size-6"
      onChange={handleChange(form)}
      theme={customStyles}
    />
  )

  const CreatableSelectComponent = ({ form }) => (
    <CreatableSelect
      {...props}
      className="is-size-6"
      theme={customStyles}
      onChange={handleChange(form)}
    />
  )

  let errorMessage = submitCount > 0 ? _.get(errors, name) : ""
  // This goes way back :')
  // If name is a nested key, e.g. "schedule1.day", putting <ErrorMessage name={name} /> will display nothing.
  // Formik claims they support lodash-like dot paths here: https://formik.org/docs/api/errormessage
  // But they DON'T. Weird part is in wellness it works and that's formik@2.1.4. This project uses formik@2.1.5 - I'd rather not roll back to a previous formik patch.
  // So this is a vanilla solution. Maybe I missed something stupidly simple, but my head hurts now. Feel free to correct this.

  return (
    <div
      className={classNames(
        "field",
        {
          [`mb-${marginBottom}`]: !!marginBottom || marginBottom >= 0,
        },
        { "mb-2": !marginBottom }
      )}
    >
      <label className={classNames("label has-text-weight-normal")}>
        {label}
        {!isRequired && (
          <span className="is-italic has-text-grey">&nbsp;(Optional)</span>
        )}
        {!!props.helper && (
          <span
            className={classNames(
              "help has-text-weight-normal has-text-grey",
              props.helperClassName
            )}
          >
            {props.helper}
          </span>
        )}
      </label>
      <Field>
        {({ form }) =>
          isSelectable ? (
            <CreatableSelectComponent form={form} />
          ) : (
            <SelectComponent form={form} />
          )
        }
      </Field>{" "}
      <p className="help is-danger">
        {!!errors
          ? !!errorMessage?.value
            ? errorMessage?.value
            : errorMessage
          : null}
      </p>
    </div>
  )
}

export default FormSelect
