import React, { Fragment, useEffect, useState, useRef } from "react"
import classNames from "classnames"
import { useFormikContext } from "formik"
import FormInput from "./FormInput.js"
import FormSelect from "./FormSelect.js"

import calendar from "../utils/calendar.json"

import handleScrollToError from "./utils/handleScrollToError"
/**
 ** Two select fields (MM, DD) and text field (YYYY) with label and error message.
 ** Supported parameters:
 **
 ** fieldProps: {
 **               'name': "birth",
 **               'label': "Birth",
 **               'isRequired': true,
 **               'onChange': Function,
 **               ...props compatible in Field Component of Formik
 **             }
 **/
const FormDate = (fieldProps) => {
  const month = fieldProps.values?.month
  const date = fieldProps.values?.date

  const formik = useFormikContext()
  const fieldRef = useRef(null)

  const handleScrollCallback = () => {
    fieldRef.current.scrollIntoView({ block: "center" })
  }

  useEffect(() => {
    handleScrollToError({
      formikContext: formik,
      fieldName: fieldProps.name,
      callback: handleScrollCallback,
    })
  }, [formik.submitCount, formik.isValid, fieldProps.name, formik])

  let months = []
  const [dates, setDates] = useState(0)

  useEffect(() => {
    if (months.length === 0)
      calendar.map((month) => {
        return months.push({
          value: month.abbreviation,
          label: month.abbreviation,
        })
      })
  })

  const findDates = (monthInput) => {
    const selectedMonth = calendar.find(
      (month) => month.abbreviation === monthInput
    )
    const dateValues = Array.apply(null, {
      length: selectedMonth.days,
    }).map(Number.call, (Number) => ({ value: Number + 1, label: Number + 1 }))

    return dateValues
  }

  useEffect(() => {
    if (month?.value) {
      const dateValues = findDates(month.value)
      setDates(dateValues)
    }
  }, [])

  const handleChange = (values, setFieldValue) => {
    if (!!values.value) {
      const dateValues = findDates(values.value)
      setFieldValue(`${fieldProps.name}.date`, { value: "" })
      setDates(dateValues)
    }
  }

  let includedFields = fieldProps?.fieldNames
  if (!includedFields) {
    includedFields = ["month", "date", "year"]
  }

  return (
    <Fragment>
      <div className="columns is-mobile p-0 m-0 mt-1" ref={fieldRef}>
        <label className={classNames("label has-text-weight-normal")}>
          {!!fieldProps.labelIcon && (
            <span className={`icon has-text-${fieldProps.labelIconColor}`}>
              {fieldProps.labelIcon}
            </span>
          )}
          {fieldProps.label}
          {!fieldProps.isRequired && !fieldProps.hideOptional && (
            <span className="has-text-grey is-italic"> (Optional)</span>
          )}
          {!!fieldProps.helper && (
            <span
              className={classNames(
                "help has-text-weight-normal",
                fieldProps.helperClassName
              )}
            >
              {fieldProps.helper}
            </span>
          )}
        </label>
      </div>

      <div className="columns is-mobile">
        {includedFields?.includes("month") && (
          <div className="column pb-0">
            <FormSelect
              name={`${fieldProps.name}.month`}
              placeholder="MMM"
              options={months}
              onChange={handleChange}
              value={month}
              isRequired
              errors={fieldProps.errors}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        )}
        {includedFields?.includes("date") && (
          <div className="column pb-0">
            <FormSelect
              name={`${fieldProps.name}.date`}
              placeholder="DD"
              options={dates}
              isDisabled={!!!fieldProps?.values?.month?.value}
              value={date}
              isRequired
              errors={fieldProps.errors}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        )}
        {includedFields?.includes("year") && (
          <div className="column pb-0">
            <FormInput
              name={`${fieldProps.name}.year`}
              pattern="[1-2]{1}[0-9]{3}"
              inputmode="numeric"
              placeholder="YYYY"
              maxLength={4}
              onChange={fieldProps.onChange}
              type="number"
              isRequired
            />
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default FormDate
